.button {
    position: absolute;
    right: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 30px;
    max-height: 20px;
    margin: 0;
    padding: 0;

    border: none;

    background: transparent;

    cursor: pointer;
}

.icon {
    color: var(--icon-background-color);
    height: 24px;
    width: auto;
}

.icon:hover {
    color: var(--button-active-background-color);
}
