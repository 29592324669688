.form {
    display: flex;
    flex-direction: column;
}

.inputGroup {
    position: relative;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding: 10px 0;
}

.inputGroup * {
    box-sizing: border-box;
}

.field {
    margin-bottom: 7px;
    padding: 8px 20px;

    border: none;

    background: var(--input-background-color);

    color: #fff;

    font-size: 12px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.field::-webkit-outer-spin-button,
.field::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.field[type="number"] {
    -moz-appearance: textfield;
}

.label {
    margin-top: 10px;
    padding: 0 0 17px 0;

    font-weight: 700;
}

.select {
    display: inline-block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 8px 20px;

    border: none;
    outline: none;

    background: var(--input-background-color);
    /*css стрелка селекта*/
    background-image: linear-gradient(45deg, transparent 50%, #fff 50%),
        linear-gradient(135deg, #fff 50%, transparent 50%),
        linear-gradient(
            to right,
            var(--input-background-color),
            var(--input-background-color)
        );
    background-position: calc(100% - 23px) 14px, calc(100% - 18px) 14px, 100% 0;
    background-size: 6px 6px, 6px 7px, 2.5em 2.5em;
    background-repeat: no-repeat;

    color: #fff;

    font-family: "Montserrat", sans-serif;
}

.select:focus {
    box-shadow: 0 0 0 1px #fff;
}

.error {
    position: absolute;
    bottom: 0;

    color: var(--form-warning-color);

    font-weight: 300;
}
