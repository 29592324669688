.closeIcon {
    width: 15px;
    height: 15px;

    color: var(--icon-background-color);
}

.deleteButton {
    position: absolute;
    right: 28px;
    top: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 17x;
    max-height: 17px;
    margin: 0;
    padding: 0;

    border: none;

    background: transparent;

    cursor: pointer;
}

.deleteButton:focus {
    box-shadow: 0 0 0 2px var(--bad-status-text-color);
}

.deleteButton:focus > .closeIcon,
.closeIcon:hover {
    color: var(--bad-status-text-color);
}
